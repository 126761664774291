import React, { useState } from "react";
import PropTypes from "prop-types";
//import AniLink from "gatsby-plugin-transition-link/AniLink";
import TransitionLink from "gatsby-plugin-transition-link";

import "./../sass/nav.scss";

const Nav = ({ menu, className }) => {
  const [menuToggled, setMenuToggled] = useState(false);
  const toggleMenu = function() {
    const checkbox = document.getElementById("tank-menu-toggle");
    const toggle = document.querySelector(".tank-nav__menu-toggle-link");
    const site_main = document.getElementById("site-content");
    const site_menu = document.getElementById("tank-menu");
    const body = document.querySelector("body");
    if (checkbox.checked) {
      const menu_height = site_menu.getBoundingClientRect().height;
      site_main.style.top = `${menu_height}px`;
      toggle.classList.add("open");
      body.classList.add("nav-open");
      setMenuToggled(true);
    } else {
      site_main.style.top = `0`;
      toggle.classList.remove("open");
      body.classList.remove("nav-open");
      setMenuToggled(false);
    }
  };

  return (
    <nav className={`tank-nav ${!menuToggled ? className : ""}`}>
      <input
        type="checkbox"
        id="tank-menu-toggle"
        className="tank-nav__menu-toggle"
        onChange={toggleMenu}
      />

      <label className="tank-nav__menu-contact-link">
        <span tabIndex="0" className={`tank-nav__menu-contact-text`}>
          <span className="contact-text">
            <TransitionLink to="/contact">Contact</TransitionLink>
          </span>
        </span>
      </label>

      <label htmlFor="tank-menu-toggle" className="tank-nav__menu-toggle-link">
        <span tabIndex="0" className={`tank-nav__menu-toggle-text`}>
          <span className="toggle-text">Menu</span>
        </span>
      </label>

      <div className="tank-menu" id="tank-menu">
        <div className="">
          <ul className="tank-menu__list l-content l-content--fixed">
            {menu.items.map((item, i) => (
              <li key={i} className="tank-menu__item">
                {/* <AniLink
                  swipe
                  direction="left"
                  top="entry"
                  duration={0.4}
                  to={item.object_slug}
                  className="tank-menu__link"
                  activeClassName="nav-active"
                >
                  {item.title}
                </AniLink> */}
                <TransitionLink
                  to={`/${item.object_slug}`}
                  className="tank-menu__link t-h3"
                  onClick={() =>
                    document.getElementById("tank-menu-toggle").click()
                  }
                >
                  {item.title}
                </TransitionLink>
                {item.description ? (
                  <p className="tank-menu__item-desc">{item.description}</p>
                ) : null}
                {item.wordpress_children ? (
                  <ul className="tank-menu__sub-menu">
                    {item.wordpress_children.map((child, iChild) => (
                      <li key={iChild} className="tank-menu__sub-menu__item">
                        {/* <AniLink
                          swipe
                          direction="left"
                          top="entry"
                          duration={0.4}
                          to={child.object_slug}
                          className="tank-menu__link"
                          activeClassName="nav-active"
                        >
                          {child.title}
                        </AniLink> */}
                        <TransitionLink
                          to={`/${child.object_slug}`}
                          className="tank-menu__link t-h3"
                          onClick={() =>
                            document.getElementById("tank-menu-toggle").click()
                          }
                        >
                          {child.title}
                        </TransitionLink>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

Nav.propTypes = {
  menu: PropTypes.object
};

export default Nav;
