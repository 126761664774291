import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import tankMobileLogo from "../../images/TankMobileLogo.png";

import Nav from "./Nav";

import "./../sass/header.scss";

const Header = ({ className }) => {
  const {
    menu: {
      edges: [{ node: menu }]
    }
  } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      menu: allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main" } }) {
        totalCount
        edges {
          node {
            items {
              title
              url
              description
              object_slug
              wordpress_children {
                title
                url
                object_slug
              }
            }
            name
          }
        }
      }
    }
  `);

  return (
    <header className="tank-header">
      <AniLink
        swipe
        direction="left"
        duration={0.4}
        className="tank-header__logo"
        to="/"
      >
        Tank Services
        <img
          className={`mobile-logo ${className}`}
          src={tankMobileLogo}
          alt="Tank Mobile Header Logo"
        />
      </AniLink>
      <Nav menu={menu} className={className} />
    </header>
  );
};

export default Header;
