import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TransitionLink from "gatsby-plugin-transition-link";

import Header from "./Header";
import Footer from "./Footer";
import tankDesktopLogo from "../../images/tankDesktopLogo.png";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./../sass/main.scss";
import "./../sass/layout.scss";

const Layout = ({ title = "", children }) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined" && window.innerWidth > 992) {
        if (window.scrollY > lastScrollY) {
          // if scroll down hide the navbar
          setShow(false);
        } else {
          // if scroll up show the navbar
          setShow(true);
        }

        // remember current page location to use in the next move
        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className="tank">
      <TransitionLink className={`tank__logo ${!show ? "hidden" : ""}`} to="/">
        <img
          className="desktop-logo"
          src={tankDesktopLogo}
          alt="Tank Desktop Header Logo"
        />
      </TransitionLink>
      <div id="site-content" className="tank__content">
        <Header className={!show ? "hidden" : ""} />
        <main id="site-main" className="site-main" role="main">
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
